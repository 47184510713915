import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
    Account,
    AccountIcon,
    CardCount,
    CardIcon,
    CardSection,
    ContactLink,
    ContactLinkLabel,
    Desktop,
    Group,
    LoginButton,
    Logo,
    MaxWidthWrapper,
    MenuIcon,
    MySWButton,
    NavigationAccount,
    NavigationAccountCloseButton,
    NavigationAccountHeader,
    NavigationAccountLabel,
    NavigationAccountLink,
    NavigationAccountMail,
    NavigationCloseButton,
    NavigationDesktop,
    NavigationEmptyMessage,
    NavigationItem,
    NavigationMobile,
    NavigationNotification,
    NavigationNotificationCloseButton,
    NavigationNotificationDetail,
    NavigationNotificationDetailDate,
    NavigationNotificationDetailDateUnread,
    NavigationNotificationDetailDescription,
    NavigationNotificationDetailTitle,
    NavigationNotificationIcon,
    NavigationNotificationShowMoreLink,
    NavigationNotifications,
    NotificationIcon,
    NotificationUnread,
    Notifications,
    Row,
    SearchCloseButton,
    SearchIcon,
    SearchInput,
    SearchSection,
} from '@components/Header/Header.elements';
import { Icons } from '@components/Icon';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { ENTER_KEY_CODE } from '@components/Modal/Login/LoginModal';
import useCart from '@hooks/useCart';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate, { ButtonSizes } from '@ui/Button/Button';
import { formatNotificationDate } from '@utils/time.utils';
import { publicAssets } from '@utils/url.utils';
import Cookies from 'js-cookie';
import {
    ACCESS_TOKEN_KEY,
    COOKIES_KEY,
    GLOBAL_SEARCH_COMPONENT_ID,
    HEADER_COMPONENT_ID,
    REFRESH_TOKEN_KEY,
    ROUTES,
    getBaseUrl,
} from '../../constants';
import { NotificationCategory } from '../../interfaces/Calendar';
import { authApi } from '../../services/auth.service';
import { calendarApi } from '../../services/calendar.service';
import { RootState } from '../../store';
import { openModalType, setIsLoggedIn as setIsLoggedInState } from '../../store/ui';
import { breakpoints, theme } from '../../styles/theme';
import { ModalTypes } from '../Modal/Modal';

interface HeaderProps {}

const CARD_BUTTON_ID = 'card-button';
const CARD_VIEWER_ID = 'card-viewer';

const HEADER_ID = 'header';

const NAVIGATION_ITEMS_KEYS = [
    { title: 'header.courses', path: ROUTES.TRAININGS },
    { title: 'header.conference', path: ROUTES.CONFERENCES },
    { title: 'header.academy', path: ROUTES.ACADEMY },
    { title: 'header.subscription', path: ROUTES.SUBSCRIPTION },
    { title: 'header.companyCourses', path: ROUTES.COMPANY_COURSES },
    { title: 'header.classroomRent', path: ROUTES.CLASSROOM_RENT },
    { title: 'header.about', path: ROUTES.ABOUT_US },
    { title: 'header.contact', path: ROUTES.CONTACT },
];

const HeaderTemplate: FC<HeaderProps> = () => {
    const { t } = useTranslations();

    const searchInputRef = useRef<HTMLInputElement>();

    const router = useRouter();

    const { getItemsFromCart } = useCart();

    const dispatch = useDispatch();

    const isLoggedInState = useSelector((state: RootState) => state.ui.isLoggedIn);
    const countOfProductsInCart = useSelector((state: RootState) => state.checkout.countOfProductsInCart);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isAccountOpen, setIsAccountOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isNotoficationOpen, setIsNotificationOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [notificationsTotalCount, setNotificationsTotalCount] = useState(0);

    const mobileRef = useRef();

    useOnClickOutside(
        mobileRef,
        () => {
            if (isMobileOpen) {
                toggleDisabledBackground();
                setIsMobileOpen(false);
            }

            if (isAccountOpen) {
                setIsAccountOpen(false);
            }

            if (isNotoficationOpen) {
                setIsNotificationOpen(false);
            }
        },
        []
    );

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const element = document.getElementById(HEADER_ID);

            if (element) {
                if (window.scrollY > 0) {
                    element.style.boxShadow = `0 4px 4px ${theme.colors.secondaryGrey}`;
                } else {
                    element.style.boxShadow = 'unset';
                }
            }
        });

        document?.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible' && isLoggedIn && !Cookies.get(ACCESS_TOKEN_KEY)) {
                router.reload();
            }
        });
    }, [isLoggedIn]);

    useEffect(() => {
        const cardButton = document.getElementById(CARD_BUTTON_ID);
        const cardViewer = document.getElementById(CARD_VIEWER_ID);

        if (cardButton && cardViewer && window.innerWidth > breakpoints.sm) {
            cardButton.addEventListener('mouseover', () => {
                cardViewer.style.display = 'block';
            });
            cardButton.addEventListener('mouseout', () => {
                cardViewer.style.display = 'none';
            });
            cardViewer.addEventListener('mouseover', () => {
                cardViewer.style.display = 'block';
            });
            cardViewer.addEventListener('mouseout', () => {
                cardViewer.style.display = 'none';
            });
        }

        if (window.innerWidth > breakpoints.sm) {
            setIsSearchOpen(true);
        }
    }, []);

    useEffect(() => {
        setIsLoggedIn(isLoggedInState);
    }, [isLoggedInState]);

    useEffect(() => {
        if (isLoggedIn) {
            authApi
                .getUser()
                .then(({ data }) => {
                    setUser(data);
                })
                .catch(() => logout());

            calendarApi
                .getMyNotifications()
                .then(({ data }) => {
                    setNotifications(data?.results);
                    setNotificationsTotalCount(data?.total_count);
                })
                .catch();
        } else {
            setUser(null);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            setInterval(async () => {
                calendarApi
                    .getMyNotifications()
                    .then(({ data }) => {
                        setNotifications(data?.results);
                        setNotificationsTotalCount(data?.totalCount);
                    })
                    .catch();
            }, 10 * 60 * 1000);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (
            !countOfProductsInCart &&
            !router.pathname.includes(ROUTES.CART) &&
            !router.pathname.includes(ROUTES.SHOP_PARTICIPANTS) &&
            !router.pathname.includes(ROUTES.SHOP_BILLING) &&
            !router.pathname.includes(ROUTES.SHOP_PAYMENT) &&
            !router.pathname.includes(ROUTES.SHOP_SUMMARY) &&
            !router.pathname.includes(ROUTES.SHOP_CONFIRMATION)
        ) {
            getItemsFromCart(true);
        }
    }, []);

    const getNotificationIconByCategory = (category: NotificationCategory) => {
        if (category === NotificationCategory.notice) return Icons.Notice;
        if (category === NotificationCategory.futureCourse || category === NotificationCategory.futureCourseReminder)
            return Icons.FutureCourse;
        if (category === NotificationCategory.recording || category === NotificationCategory.recordingReminder)
            return Icons.Record;
        if (category === NotificationCategory.recommendation) return Icons.Recommendation;
    };

    const handlePreviousNotifications = () => {
        calendarApi
            .getMyNotifications(notifications?.length + 10)
            .then(({ data }) => {
                setNotifications(data?.results);
                setNotificationsTotalCount(data?.total_count);
            })
            .catch();
    };

    const logout = async () => {
        if (Cookies.get(REFRESH_TOKEN_KEY) && Cookies.get(REFRESH_TOKEN_KEY) !== 'undefined') {
            await authApi.logout();
        }

        Cookies.remove(ACCESS_TOKEN_KEY);
        Cookies.remove(REFRESH_TOKEN_KEY);
        dispatch(setIsLoggedInState(false));

        router.push({
            pathname: ROUTES.HOME,
        });
    };

    const handleSearch = () => {
        if (searchInputRef.current.value !== '') {
            router.push({
                pathname: ROUTES.SEARCH,
                query: {
                    search: searchInputRef.current.value,
                },
            });
        }
    };

    const handleRedirect = (path: string) => {
        return router.push(path);
    };

    const handleLoginOrLogout = () => {
        if (isLoggedIn) return logout();

        if (isMobileOpen) {
            toggleDisabledBackground();
            setIsMobileOpen(false);
        }
        dispatch(
            openModalType({
                type: ModalTypes.LOGIN,
            })
        );
    };

    const toggleDisabledBackground = (withHeader?: boolean) => {
        const divs = document.querySelectorAll(
            `body > div > div:not(#${GLOBAL_SEARCH_COMPONENT_ID}):not(#${withHeader ? null : HEADER_COMPONENT_ID}`
        );

        // @ts-ignore
        [...divs].map((div) => (div.style.opacity = isMobileOpen || isSearchOpen ? 1 : 0.2));
    };

    return (
        <>
            <Desktop id={HEADER_ID}>
                <MaxWidthWrapper>
                    <Row>
                        <Logo onClick={() => handleRedirect(ROUTES.HOME)} src={publicAssets('/logo.png')} />
                        <Group>
                            {isSearchOpen && (
                                <SearchSection>
                                    <SearchInput
                                        onKeyUp={(e) => e.keyCode === ENTER_KEY_CODE && handleSearch()}
                                        defaultValue={
                                            !router?.pathname?.includes(ROUTES.MY_PV) &&
                                            !router?.pathname?.includes(ROUTES.TRAININGS) &&
                                            !router?.pathname?.includes(ROUTES.CONFERENCES)
                                                ? router.query?.search
                                                : ''
                                        }
                                        placeholder={t('header.search')}
                                        ref={searchInputRef}
                                    />
                                    <ButtonTemplate
                                        onClick={handleSearch}
                                        text={t('header.search.searchButton')}
                                        whiteType
                                        size={ButtonSizes.SMALL}
                                    />
                                    <SearchCloseButton onClick={() => setIsSearchOpen(false)}>
                                        {t('header.search.closeButton')}
                                    </SearchCloseButton>
                                </SearchSection>
                            )}

                            {isLoggedIn && (
                                <MySWButton>
                                    <ButtonTemplate
                                        text={t('header.mySW')}
                                        onClick={() => router.push({ pathname: ROUTES.MY_PV_COURSES })}
                                    />
                                </MySWButton>
                            )}
                            <CardSection id={CARD_BUTTON_ID} onClick={() => router.push({ pathname: ROUTES.CART })}>
                                {countOfProductsInCart > 0 && <CardCount>{countOfProductsInCart}</CardCount>}
                                <CardIcon name={Icons.ShoppingCart} />
                            </CardSection>
                            {!isLoggedIn && (
                                <LoginButton>
                                    <ButtonTemplate
                                        onClick={handleLoginOrLogout}
                                        text={t('header.login')}
                                        size={ButtonSizes.SMALL}
                                    />
                                </LoginButton>
                            )}
                            <SearchIcon
                                onClick={() => {
                                    setIsSearchOpen(true);
                                }}
                                name={Icons.Search}
                            />
                            <MenuIcon
                                onClick={() => {
                                    toggleDisabledBackground();
                                    setIsMobileOpen(!isMobileOpen);
                                }}
                                name={Icons.Menu}
                            />
                            {isLoggedIn && (
                                <Notifications>
                                    <NotificationIcon
                                        name={Icons.Notification}
                                        onClick={() => setIsNotificationOpen(!isNotoficationOpen)}
                                    />
                                    {notifications?.length > 0 &&
                                        notifications?.filter((notification) => !notification?.read)?.length > 0 && (
                                            <NotificationUnread />
                                        )}
                                    {isNotoficationOpen && (
                                        <NavigationNotifications ref={mobileRef}>
                                            {notifications?.length === 0 && (
                                                <NavigationEmptyMessage
                                                    dangerouslySetInnerHTML={{ __html: t('notifications.empty.label') }}
                                                ></NavigationEmptyMessage>
                                            )}
                                            {notifications?.length > 0 &&
                                                notifications?.map((notification, key) => (
                                                    <NavigationNotification
                                                        key={key}
                                                        onClick={async () => {
                                                            await calendarApi.setMyNotificationRead(notification?._key);

                                                            if (notification.url) {
                                                                return (window.location.href =
                                                                    notification.url?.includes('https')
                                                                        ? notification.url
                                                                        : `${getBaseUrl()}${notification.url}`);
                                                            }
                                                        }}
                                                    >
                                                        <NavigationNotificationIcon
                                                            name={getNotificationIconByCategory(notification?.category)}
                                                        />
                                                        <NavigationNotificationCloseButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setNotifications(
                                                                    notifications?.filter(
                                                                        (filteredNotification) =>
                                                                            filteredNotification?._key !=
                                                                            notification?._key
                                                                    )
                                                                );
                                                                setNotificationsTotalCount(notificationsTotalCount - 1);
                                                                calendarApi.deleteMyNotification(notification?._key);
                                                            }}
                                                        >
                                                            &#10005;
                                                        </NavigationNotificationCloseButton>
                                                        <NavigationNotificationDetail>
                                                            <NavigationNotificationDetailTitle>
                                                                {notification?.title}
                                                            </NavigationNotificationDetailTitle>
                                                            <NavigationNotificationDetailDescription
                                                                dangerouslySetInnerHTML={{ __html: notification?.text }}
                                                            />
                                                            <NavigationNotificationDetailDate
                                                                isPrimary={!notification?.read}
                                                            >
                                                                {formatNotificationDate(notification?.created_at)}
                                                                {!notification?.read && (
                                                                    <NavigationNotificationDetailDateUnread />
                                                                )}
                                                            </NavigationNotificationDetailDate>
                                                        </NavigationNotificationDetail>
                                                    </NavigationNotification>
                                                ))}
                                            {notificationsTotalCount > notifications?.length && (
                                                <NavigationNotificationShowMoreLink
                                                    onClick={handlePreviousNotifications}
                                                >
                                                    {t('notifications.showMore.button')}
                                                </NavigationNotificationShowMoreLink>
                                            )}
                                        </NavigationNotifications>
                                    )}
                                </Notifications>
                            )}
                            {isLoggedIn && (
                                <Account ref={mobileRef}>
                                    <AccountIcon
                                        onClick={() => setIsAccountOpen(true)}
                                        name={Icons.User}
                                    />
                                    {isAccountOpen && (
                                        <NavigationAccount>
                                            <NavigationAccountHeader>
                                                <NavigationAccountCloseButton onClick={() => setIsAccountOpen(false)}>
                                                    &#10005;
                                                </NavigationAccountCloseButton>
                                                <NavigationAccountLabel>
                                                    {user?.name} {user?.surname}
                                                </NavigationAccountLabel>
                                                <NavigationAccountMail>{user?.email}</NavigationAccountMail>
                                            </NavigationAccountHeader>
                                            <NavigationAccountLabel>{t('header.myPV')}</NavigationAccountLabel>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_PV_COURSES}
                                            >
                                                {t('header.myCourses')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_PV_CONFERENCES}
                                            >
                                                {t('header.myConferences')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_PV_FAVOURITES}
                                            >
                                                {t('header.myFavourites')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_PV_MATERIALS}
                                            >
                                                {t('header.myMaterials')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLabel>{t('header.myAccount')}</NavigationAccountLabel>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_ACCOUNT_USER}
                                            >
                                                {t('header.account')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_ACCOUNT_PASSWORD}
                                            >
                                                {t('header.password')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_ACCOUNT_NOTIFICATIONS}
                                            >
                                                {t('header.agreements')}
                                            </NavigationAccountLink>
                                            <NavigationAccountLink
                                                onClick={() => setIsAccountOpen(false)}
                                                href={ROUTES.MY_ACCOUNT_ORDERS}
                                            >
                                                {t('header.orders')}
                                            </NavigationAccountLink>
                                            <ButtonTemplate
                                                marginTop={40}
                                                marginBottom={20}
                                                marginLeft={25}
                                                onClick={handleLoginOrLogout}
                                                text={t('header.logout')}
                                            />
                                        </NavigationAccount>
                                    )}
                                </Account>
                            )}
                        </Group>
                    </Row>
                    <Row hideOnTablet>
                        <NavigationDesktop>
                            {NAVIGATION_ITEMS_KEYS.map((item, key) => (
                                <NavigationItem
                                    className={`${router.pathname.includes(item.path) ? 'isActive' : ''}`}
                                    href={item.path}
                                    key={`desktop-${key}`}
                                >
                                    {t(item.title)}
                                </NavigationItem>
                            ))}
                        </NavigationDesktop>
                        <Group>
                            <ContactLink href={`tel:${t('header.phone')}`}>
                                <IconTemplate name={Icons.Phone} />
                                <ContactLinkLabel>{t('header.phone')}</ContactLinkLabel>
                            </ContactLink>
                        </Group>
                    </Row>
                </MaxWidthWrapper>
                <NavigationMobile ref={mobileRef} className={`${isMobileOpen ? 'isOpen' : 'isClose'}`}>
                    <Logo onClick={() => handleRedirect(ROUTES.HOME)} src={publicAssets('/logo.png')} />
                    {!isLoggedIn && (
                        <ButtonTemplate
                            onClick={handleLoginOrLogout}
                            text={t('header.login')}
                            size={ButtonSizes.SMALL}
                            marginTop={35}
                            marginBottom={30}
                        />
                    )}
                    {NAVIGATION_ITEMS_KEYS.map((item, key) => (
                        <NavigationItem href={item.path} key={`mobile-${key}`}>
                            {t(item.title)}
                        </NavigationItem>
                    ))}
                    <NavigationItem href={`tel:${t('header.phone')}`} marginTop={35}>
                        <IconTemplate name={Icons.Phone} />
                        {t('header.phone')}
                    </NavigationItem>
                    <NavigationItem href={`mailto:${t('header.mail')}`}>
                        <IconTemplate name={Icons.Mail} />
                        {t('header.mail')}
                    </NavigationItem>
                    <NavigationCloseButton
                        onClick={() => {
                            toggleDisabledBackground();
                            setIsMobileOpen(!isMobileOpen);
                        }}
                    >
                        x
                    </NavigationCloseButton>
                </NavigationMobile>
            </Desktop>
        </>
    );
};

export default HeaderTemplate;
